// Dependencies
import React from "react";
// Hooks & Helpers
import isLightOrDark from "../helpers/isLightOrDark";
// Components
// import Image from "../components/Image";

const Folder = ({ color = "#000000", palette, children }) => {

  const backgroundColor = palette ? palette.primary : "#EDEDED";
  const colorShade = isLightOrDark(color);

  return (
    <div className={`folder ${colorShade === 'light' ? 'light' : 'dark'}`} style={{
      color: color,
      backgroundColor: backgroundColor
    }}>
      {children}
    </div>
  )
}

export default Folder
