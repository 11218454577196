// Dependencies
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "gatsby";
// Hooks
import useSettings from "../hooks/useSettings";
import useWindowSize from "../hooks/useWindowSize";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
// Components
import Folder from "../components/Folder";
import Stripes from "../components/Stripes";
import { MenuContext, MenuActionsContext } from "../components/Store";

const Lock = () => {
  useLockBodyScroll()
  return <span />
}

const Menu = () => {

  // Content
  const { menu } = useSettings();

  // UX
  const isOpen = useContext(MenuContext);
  const setMenuOpen = useContext(MenuActionsContext);
  const [showStripes, setShowStripes] = useState(false);

  const handleTransition = useCallback(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        setShowStripes(true)
      }, 300)
    } else {
      setShowStripes(false)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [isOpen])

  useEffect(handleTransition, [isOpen])

  // UI
  const handleLinkClick = () => {
    setMenuOpen(false);
  }

  // CSS
  const { height } = useWindowSize();

  return (
    <div className={`menu fv ${isOpen ? 'is-open' : ''}`} style={{ height }}>
      <Folder>
        <div>
          {menu.map((entry, index) => (
            <section className={index < menu.length - 1 ? "border" : ""} key={`menu-item-${entry.label}`}>
              <h1>
                {entry.internalLink ? (
                  <Link to={entry.internalLink} className="bl" onClick={handleLinkClick}>{entry.label}</Link>
                ) : (
                  <a href={entry.externalLink || "/404"} className="bl" target="_blank" rel="noreferrer noopener">{entry.label}</a>
                )}
              </h1>
            </section>
          ))}
        </div>
        <Stripes open={showStripes} />
      </Folder>
      {isOpen && <Lock />}
    </div>
  )
}

export default Menu
