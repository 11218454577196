// Dependencies
import React from "react"
// Components
import CookieNotice from "../components/CookieNotice"
import Header from "../components/Header"
import Store from "../components/Store"
import Menu from "../components/Menu"
import Meta from "../components/Meta"
// CSS
import "../css/app.scss"

const Layout = ({ title, description, shareImage, children }) => (
  <Store>
    <Header isInvisible />
    <Meta title={title} description={description} shareImage={shareImage} />
    <div id="lines"><div></div></div>
    <main>{children}</main>
    <Menu />
    <CookieNotice />
  </Store>
)

export default Layout
