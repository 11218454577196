import { useState } from 'react';
import useLocalStorage from './useLocalStorage';

function useCookieNotice() {
  const [hide, set] = useLocalStorage('hideCookieNotice', false);
  // const [hide, set] = useState(false);

  const yes = () => {
    !window.ga || window.ga('set', 'anonymizeIp', 0);
    set(true)
  }

  const no = () => {
    !window.gaOptout || window.gaOptout()
    set(true)
  }

  return [hide, yes, no]
}

export default useCookieNotice
