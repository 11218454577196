// Dependencies
import React from "react"

const MenuIcon = ({ fill = "black" }) => (
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 1.15H23.8583V0H0V1.15ZM0 6.71689H23.8583V5.56689H0V6.71689ZM23.8583 12.2839H0V11.1339H23.8583V12.2839ZM0 17.8508H23.8583V16.7008H0V17.8508Z" fill={fill} />
  </svg>
)

export const SearchIcon = ({ fill = "black" }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.3996 13.2693C15.1735 10.4404 15.1565 5.89858 12.3487 3.09074C9.52373 0.265815 4.94362 0.265815 2.11869 3.09074C-0.706231 5.91567 -0.706231 10.4958 2.11869 13.3207C4.69199 15.894 8.72171 16.1232 11.5545 14.0083L18.7015 21L19.4842 20.1999L12.3996 13.2693ZM11.5572 3.88221C13.945 6.27002 13.945 10.1414 11.5572 12.5292C9.16937 14.917 5.29797 14.917 2.91017 12.5292C0.52236 10.1414 0.52236 6.27002 2.91017 3.88221C5.29797 1.49441 9.16937 1.49441 11.5572 3.88221Z" fill={fill} />
  </svg>
)

export const AboutIcon = ({ fill = "black" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11.688 4.97738C11.688 5.75125 11.1004 6.28328 10.2991 6.28328C9.49786 6.28328 8.91026 5.75125 8.91026 4.97738C8.91026 4.21964 9.49786 3.70373 10.2991 3.70373C11.1004 3.70373 11.688 4.21964 11.688 4.97738ZM8.5462 15.3222C8.05692 15.3334 7.59835 15.3439 7.0584 15.3439C6.89815 15.3439 6.7735 15.2311 6.7735 15.086V14.8603C6.7735 14.7152 6.89815 14.6023 7.0584 14.6023H8.30484C9.48006 14.6023 9.90741 14.296 9.90741 13.3448V9.62057C9.90741 8.87895 9.65812 8.74997 8.53632 8.74997H6.95157C6.79131 8.74997 6.66667 8.63712 6.66667 8.49202V8.28243C6.66667 8.15345 6.7735 8.02447 6.91595 8.02447C8.05556 7.99223 9.17735 7.86325 10.317 7.6053C10.4772 7.57305 10.6553 7.54081 10.7443 7.54081C10.9758 7.54081 11.1182 7.63754 11.1182 7.92774V13.3448C11.1182 14.296 11.5456 14.6023 12.7208 14.6023H13.7892C13.9494 14.6023 14.0741 14.7152 14.0741 14.8603V15.086C14.0741 15.2311 13.9494 15.3439 13.7892 15.3439C13.2845 15.3439 12.8529 15.3335 12.3907 15.3223C11.8582 15.3094 11.285 15.2956 10.5128 15.2956C9.70538 15.2956 9.10571 15.3093 8.5462 15.3222Z" fill={fill} />
  </svg>
)

export const InstagramIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" clipRule="evenodd" fill="black">
      <path d="M0.982613 6.50651C0.929501 7.65966 0.916667 8.01715 0.916667 11C0.916667 13.9823 0.929494 14.3414 0.981703 15.4936C1.06607 17.332 1.61055 18.6435 2.4828 19.5161C3.35498 20.3887 4.6664 20.9339 6.50632 21.0183C7.65948 21.0705 8.01767 21.0833 11 21.0833C13.9828 21.0833 14.3415 21.0705 15.4942 21.0183C17.3303 20.9338 18.6422 20.3894 19.5155 19.517C20.3886 18.6448 20.9343 17.3335 21.0174 15.4943C21.0705 14.3421 21.0833 13.9829 21.0833 11C21.0833 8.0172 21.0705 7.65938 21.0183 6.50674C20.9356 4.67091 20.3915 3.35922 19.519 2.48612C18.6464 1.613 17.3342 1.06697 15.4946 0.982621C14.3415 0.92951 13.9829 0.916667 11 0.916667C8.01766 0.916667 7.65949 0.929494 6.50727 0.981703M15.5357 21.934C14.3623 21.9872 13.9874 22 11 22C8.01258 22 7.63858 21.9872 6.46433 21.934C2.46583 21.7507 0.249333 19.5305 0.066 15.5357C0.0128333 14.3623 0 13.9874 0 11C0 8.01258 0.0128333 7.63858 0.0669167 6.46433C0.25025 2.46583 2.47042 0.249333 6.46525 0.066C7.63858 0.0128333 8.01258 0 11 0C13.9874 0 14.3623 0.0128333 15.5366 0.0669167C19.5351 0.25025 21.7543 2.47408 21.934 6.46525C21.9872 7.63858 22 8.01258 22 11C22 13.9874 21.9872 14.3623 21.9331 15.5357C21.7525 19.5342 19.5268 21.7507 15.5357 21.934ZM6.50727 0.981703C4.66924 1.06615 3.35735 1.61063 2.48481 2.4828C1.61226 3.35498 1.06697 4.66659 0.982613 6.50651" />
      <path d="M11 16.125C13.8305 16.125 16.125 13.8305 16.125 11C16.125 8.16954 13.8305 5.875 11 5.875C8.16954 5.875 5.875 8.16954 5.875 11C5.875 13.8305 8.16954 16.125 11 16.125ZM11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z" />
      <path d="M11 14C12.6569 14 14 12.6569 14 11C14 9.34315 12.6569 8 11 8C9.34315 8 8 9.34315 8 11C8 12.6569 9.34315 14 11 14ZM11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11C7 13.2091 8.79086 15 11 15Z" />
      <path d="M17.5 6.25C17.9142 6.25 18.25 5.91421 18.25 5.5C18.25 5.08579 17.9142 4.75 17.5 4.75C17.0858 4.75 16.75 5.08579 16.75 5.5C16.75 5.91421 17.0858 6.25 17.5 6.25ZM17.5 7C18.3284 7 19 6.32843 19 5.5C19 4.67157 18.3284 4 17.5 4C16.6716 4 16 4.67157 16 5.5C16 6.32843 16.6716 7 17.5 7Z" />
    </g>
  </svg>
)

export const Chevron = ({ fill = "#000000" }) => (
  <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M0.570862 0.26034C0.821811 0.0233328 1.21738 0.0346346 1.45438 0.285584L9.5 8.80447L17.5456 0.285584C17.7826 0.0346346 18.1782 0.0233328 18.4291 0.26034C18.6801 0.497347 18.6914 0.892914 18.4544 1.14386L9.5 10.625L0.545618 1.14386C0.308611 0.892914 0.319913 0.497347 0.570862 0.26034Z" />
  </svg>
)

export const Cross = ({ fill = "#000000" }) => (
  <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M24.9999 25.0001L1.99989 2.00011L3.06055 0.939453L26.0605 23.9395L24.9999 25.0001Z" />
      <path d="M25.0001 2.00011L2.00011 25.0001L0.939453 23.9395L23.9395 0.939453L25.0001 2.00011Z" />
    </g>
  </svg>
)

export const AudioIcon = ({ fill = "#000000" }) => (
  <svg width="42" height="35" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M22.9009 0.343254C23.1401 0.475194 23.2887 0.72679 23.2887 1V34C23.2887 34.2732 23.1401 34.5248 22.9009 34.6567C22.6616 34.7887 22.3696 34.7801 22.1385 34.6343L9.24481 26.5H1C0.585786 26.5 0.25 26.1642 0.25 25.75V9.25C0.25 8.83579 0.585786 8.5 1 8.5H9.24482L22.1385 0.365682C22.3696 0.219906 22.6616 0.211314 22.9009 0.343254ZM21.7887 2.35994L9.8618 9.88432C9.74201 9.95989 9.60326 10 9.46163 10H1.75V25H9.46163C9.60326 25 9.74201 25.0401 9.8618 25.1157L21.7887 32.6401V2.35994ZM35.2469 4.60944C35.5317 4.30863 36.0064 4.29561 36.3072 4.58036C39.651 7.74566 41.75 12.3662 41.75 17.5C41.75 22.6338 39.651 27.2544 36.3072 30.4197C36.0064 30.7045 35.5317 30.6914 35.2469 30.3906C34.9622 30.0898 34.9752 29.6151 35.276 29.3304C38.32 26.4489 40.25 22.2225 40.25 17.5C40.25 12.7776 38.32 8.5512 35.276 5.66971C34.9752 5.38495 34.9622 4.91026 35.2469 4.60944ZM29.9364 10.6293C30.2104 10.3187 30.6843 10.289 30.995 10.563C32.8723 12.2192 34.0578 14.7181 34.0578 17.5001C34.0578 20.1046 33.0188 22.4607 31.3462 24.1096C31.0512 24.4004 30.5764 24.397 30.2856 24.102C29.9948 23.807 29.9982 23.3322 30.2931 23.0414C31.6797 21.6745 32.5578 19.7032 32.5578 17.5001C32.5578 15.1465 31.5557 13.0579 30.0026 11.6879C29.692 11.4139 29.6624 10.9399 29.9364 10.6293Z" />
  </svg>
)

export default MenuIcon
