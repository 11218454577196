// Dependencies
import React, { useState } from "react";
// import { Link } from "gatsby";
// Hooks
// import useWindowSize from "../hooks/useWindowSize";
// Components
// import Folder from "../components/Folder";
// import Stripes from "../components/Stripes";

// Context Store
export const MenuContext = React.createContext();
export const MenuActionsContext = React.createContext();
export const HeaderContext = React.createContext();
export const HeaderActionsContext = React.createContext();

const Store = ({ children }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  return (
    <div id="layout">
      <MenuContext.Provider value={menuOpen}>
        <MenuActionsContext.Provider value={setMenuOpen}>
          <HeaderContext.Provider value={showHeader}>
            <HeaderActionsContext.Provider value={setShowHeader}>
              {children}
            </HeaderActionsContext.Provider>
          </HeaderContext.Provider>
        </MenuActionsContext.Provider>
      </MenuContext.Provider>
    </div>
  )
}

export default Store
