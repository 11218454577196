// Dependencies
import React, { useState, useEffect, useContext } from "react";
// Components
import Link from "../components/Link";
import MenuIcon, { SearchIcon, AboutIcon, InstagramIcon, Cross } from "../components/Icons";
import { MenuContext, MenuActionsContext, HeaderContext, HeaderActionsContext } from "../components/Store";
// Hooks
import useSettings from "../hooks/useSettings";
// Assets
import LOGO from "../images/logo.svg";

const Header = ({ siteTitle, isInvisible, sticky }) => {

  // Content
  const { instagram } = useSettings();

  // UX
  const menuIsOpen = useContext(MenuContext);
  const setMenuOpen = useContext(MenuActionsContext);
  const showFixedHeader = useContext(HeaderContext);
  const setShowFixedHeader = useContext(HeaderActionsContext);

  // UI
  const handleMenuClick = () => {
    setMenuOpen(o => !o) // toggle
  }

  let isShowing = false; // Flag as its a DOM event...
  const handleMouseMove = ({ clientY }) => {
    const scrollDistance = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    if (scrollDistance > 100) {
      if ((clientY < 70) && !isShowing) {
        isShowing = true
        setShowFixedHeader(true)
      } else if ((clientY >= 70) && isShowing) {
        isShowing = false
        setShowFixedHeader(false)
      }
    }
  }

  useEffect(() => {
    if (isInvisible) {
      window.addEventListener('mousemove', handleMouseMove, false);
    }
    return () => {
      if (isInvisible) {
        window.removeEventListener('mousemove', handleMouseMove, false);
      }
    }
  }, [])

  return (
    <header className={`${isInvisible ? 'invisible' : ''} ${showFixedHeader || menuIsOpen ? 'show' : ''} ${sticky ? 'sticky' : ''}`}>
      <div className="container">
        <Link className="lockup" to="/">
          <img src={LOGO} className="mrx2" alt="logo" />
          <span>
            Giving Helpful Advice <br />
            To Creative People
          </span>
        </Link>
        {!menuIsOpen ? (
          <nav>
            <Link to="/advice" className="icon"><SearchIcon /></Link>
            <Link to="/about" className="icon"><AboutIcon /></Link>
            <a href={instagram} className="icon" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
            <button className="icon" onClick={handleMenuClick}><MenuIcon /></button>
          </nav>
        ) : (
          <nav>
            <button className="icon" onClick={handleMenuClick}><Cross /></button>
          </nav>
        )}

      </div>
    </header>
  )
}

export default Header
