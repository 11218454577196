// Dependencies
import React from 'react'
// Hooks
import useSettings from '../hooks/useSettings'
import useCookieNotice from '../hooks/useCookieNotice'
// Components
import Markdown from '../components/Markdown'

const CookieNotice = () => {

  const { cookieNoticeCopy, cookieNoticePrivacyPolicy } = useSettings();
  const [hide, yes, no] = useCookieNotice();

  return (
    !hide && (
      <div className="cookie-notice has-light-links pad">
        <div>
          <Markdown field={cookieNoticeCopy} />
          <button onClick={yes} className="light-link">Yes</button>
          <span>{" or "}</span>
          <button onClick={no} className="light-link">No</button>
        </div>
        <Markdown field={cookieNoticePrivacyPolicy} />
      </div>
    )
  )
}

export default CookieNotice
