import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          ##
          defaultMetaTitle
          defaultMetaDescription
          defaultShareImage {
            fluid(maxWidth: 640) {
              src
            }
          }
          adviceCategories {
            ...CategoryFragment
          }
          menu {
            label
            internalLink
            externalLink
          }
          instagram
          newsletterTitle
          newsletterCopy {
            childMarkdownRemark {
              html
            }
            text: newsletterCopy
          }
          newsletterDisclaimer {
            childMarkdownRemark {
              html
            }
          }
          newsletterSuccess {
            childMarkdownRemark {
              html
            }
          }
          newsletterTryAgain {
            childMarkdownRemark {
              html
            }
          }
          readMoreAboutTheFoundation
          letPaulKnow
          letPaulKnowEmail
          letPaulKnowLabel
          moreAboutThis
          pageNotFound {
            json
          }
          cookieNoticeCopy {
            childMarkdownRemark {
              html
            }
          }
          cookieNoticePrivacyPolicy {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
