// Dependencies
import React, { useState } from "react";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
import isLightOrDark from "../helpers/isLightOrDark";
// Components
// ...

const CategoryStripe = ({ category, inView, open, count }) => {

  // Content
  const { title, slug, palette } = category

  // UX
  const [peek, setPeek] = useState(false);

  // UI
  const handlers = {
    onMouseEnter: () => setPeek(true),
    onMouseLeave: () => setPeek(false)
  };

  // CSS
  const shade = isLightOrDark(palette.color);

  return (
    <div className={`category h1 ${shade}`} {...handlers} style={{
      transform: inView || open ? `translateY(0%)` : `translateY(${54 * count}%)`
    }}>
      <div className="stripe" style={{
        color: palette.color,
        backgroundColor: palette.primary,
        transform: peek ? `translateY(-15%)` : `translateY(0%)`
      }}>
        <div>
          <Link to={`/advice/${slug}`}>{title}</Link>
        </div>
      </div>
    </div>
  )
}

const Stripes = ({ activeCategory, open }) => {

  // Content
  const { adviceCategories } = useSettings();
  const categories = activeCategory ? adviceCategories.filter(cat => cat.slug !== activeCategory.slug) : adviceCategories;

  // UX
  const [ref, inView] = useInView({ threshold: 0.95 });

  return (
    <div className="stripes" ref={ref}>
      {categories.map((category, index) => {
        // Reverse count of the index, to calculate the spacing
        const count = (adviceCategories.length - 1) - index;

        return (
          <CategoryStripe category={category} inView={inView} open={open} count={count} key={`${category.slug}-${index}`} />
        )
      })}
    </div>
  )
}

export default Stripes
